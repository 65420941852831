import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'

const bg = require('../../images/banner/bnr4.jpg')

class TidwellDetails extends Component {


    render() {
        return (
            <>
                <Header/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">Sagebrush Mine</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>Portfolio</li>
                                        <li>Portfolio Detail</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block">
                        <div className="section-full content-inner bg-white">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 m-b30">
                                        <h2 className="text-black font-weight-600 m-b15">Project Information</h2>
                                        <p>The Sage Brush Prospect is located in Emery County, Utah adjacent to the Energy Fuels deposit, which  produced historically over 4 million pounds of U308 and over 6 million pounds of vanadium.  With over 2 million pounds of U308 and 3 million pounds of vanadium in the ground.  </p>
                                        <p>Grand Central Silver Mines has 32 located mining claims is this very favorable area for future discoveries of uranium and vanadium.  Mapping and a scintillometer survey is planned for January and February of 2024. </p>
                                        <div className="row widget widget_getintuch widget_getintuch-pro-details m-lr0">
                                            {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-user"></i>
                                                    <strong>CLIENT</strong> Martin Stewart
                                                </div>
                                            </div> */}
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-location-pin"></i>
                                                    <strong>LOCATION</strong>Emery County, UT
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-ruler-alt-2"></i>
                                                    <strong>SIZE</strong>4,000<sup>acres</sup>
                                                </div>
                                            </div>
                                            {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-home"></i>
                                                    <strong>TYPE</strong>Residential Project
                                                </div>
                                            </div> */}
                                        </div>
                                        <h2 className="text-black font-weight-600 m-b15">Current Scope Of Work</h2>
                                        <p>Ore bodies were being mined in the Tidwell mineral belt from 100 to 650 feet beneath the surface, and virgin ore bodies are known to exist at depths from 400 to 1,000 feet or more.  At least four areas of favorable ground for future exploration and development are 1) the northeast extensions of known trends in the Tidwell mineral belt.</p>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-lg-12 m-b30">
                                                <img alt="" src={require("../../images/about/pic3.jpg")}/>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <img alt="" src={require("../../images/our-services/pic2.jpg")}/>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <img alt="" src={require("../../images/our-services/pic3.jpg")}/>
                                            </div>
                                            <div className="col-lg-12 m-b30">
                                                <img alt="" src={require("../../images/about/about2.jpg")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}
export default TidwellDetails;
