import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header10 from '../layout/header10'
import Footer10 from '../layout/footer10'

const bg = require('../../images/banner/bnr2.jpg')

class Projects extends Component {


    render() {
        return (
            <>
                <Header10/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">Current Projects</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>Projects</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block">
                        <div className="section-full content-inner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-4 col-md-5">
                                        <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                                            <ul className="menu">
                                                <li><Link to="#">all projects</Link> </li>
                                                <li className="active"><Link to="/bradshaw">Lincoln Cave</Link>  </li>
                                                <li className="active"><Link to="mesa-mine">Mesa Mine</Link>  </li>
                                                <li className="active"><Link to="tidwell">Sagebrush Mine</Link>  </li>
                                            </ul>
                                        </div>
                                        <div className="widget">
                                            <div className="download-file">
                                                <h4 className="title">Get your brochures</h4>
                                                <ul>
                                                    <li>
                                                        <Link to="#" target="_blank">
                                                            <div className="text">Company Brochures</div>
                                                            <i className="fa fa-download"></i>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" target="_blank">
                                                            <div className="text">Company Info</div>
                                                            <i className="fa fa-download"></i>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-9 col-lg-8 col-md-7 m-b30">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 m-b30">
                                                <div className="dlab-box">
                                                    <div className="dlab-media"> <Link to="#"><img src={require("../../images/about/pic6.jpg")} alt=""/></Link> </div>
                                                    <div className="dlab-info m-t30 ">
                                                        <h4 className="dlab-title m-t0"><Link to="#">Exploration Strategy </Link></h4>
                                                        <p>Our current project are direct toward providing minerals necessary for non-fossil fuel energy generation. These metals include uranium, cobalt, nickel, and copper. </p>
                                                        <p>These alternatives to fossil fuel-based energy include nuclear power and electric battery production. </p>
                                                        <p>The United States has few proven reserves of cobalt and put our country at a strategic disadvantage. Therefore, acquiring prospective sites of cobalt deposits will have future importance to energy production in the United States. </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="dlab-box">
                                                    <div className="dlab-media m-b30 p-b5"> <Link to="#"><img src={require("../../images/our-services/pic2.jpg")} alt=""/></Link></div>
                                                    <div className="dlab-media"> <Link to="#"><img src={require("../../images/our-services/pic3.jpg")} alt=""/></Link></div>
                                                    <div className="dlab-info m-t30">
                                                        <h4 className="dlab-title m-t0"><Link to="#">Joint Venture</Link></h4>
                                                        <p>We are currently partnered with Ivanhoe Electric, who is engaging in an exploratory drilling program in the Lincoln Mineral District of Beaver County, UT. </p>
                                                        <p>Results from that drilling will be provided by Ivanhoe as they continue their drilling. </p>
                                                        <p>The projected mineral return will be copper, gold, silver and possibly molybdenum. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer10/>
            </>
        )
    }
}
export default Projects;
