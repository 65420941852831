import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header10 from '../layout/header10'
import Footer10 from '../layout/footer10'

var bnr3 = require('./../../images/banner/bnr3.jpg');

class About2 extends Component {


    render() {
        return (
            <>
                <Header10/>

                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">About Us</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to={"/"}>Home</Link></li>
                                        <li>About Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block">
                        <div className="section-full content-inner bg-white">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-12 m-b30">
                                        <div className="our-story">
                                            <span>OUR STORY</span>
                                            <h2 className="title">Providing <br/>Mineral Resources For A Changing World <br/><span className="text-primary">since 1984</span></h2>
                                            <p>Grand Central Silver Mines, Inc. ("GCSM," "we," "us," or the "Company") is engaged in the holding, exploration, and disposition of mineral properties that we own or lease. Many of our mineral properties are in established mining districts that have large and profitable mineral production histories, and are believed to contain deposits of gold, copper, lead, zinc, silver, cobalt nickel, and uranium. The company currently owns, or holds an interest in three significant projects, most of which were past producers of gold, silver and other metals (approx 20,000 acres of mining leases). Our corporate strategy is currently focused on the testing and evaluation of the type and amount of potential mineral deposits located on our properties for the purpose of maximizing the value to be realized by the Company and its shareholders upon the disposition or development of such mineral properties.

Since 1984, the Company has been organized as a corporation under the laws of the State of Utah. In March 2009 the company moved its principal offices from Carrollton, Texas to its temporary offices in Hoffman Estates, Illinois.

GCSM is the result of two predecessor companies. The first predecessor company, Winston Research, Inc. ("Winston"), was formed as a Utah corporation on June 21, 1984. The second predecessor company, Diamond Bullion Corporation ("Diamond") was formed on October 15, 1984, also as a Utah corporation. On October 31, 1984, Winston and Diamond merged. As a result of the merger, Winston acquired all of Diamond's assets in exchange for the Winston's common stock and Winston, as the surviving corporation, changed its name to Diamond Bullion Corporation. This new Diamond Bullion Corporation was also organized under the laws of the State of Utah. On December 16, 1985, Diamond Bullion Corporation changed its name to Centurion Mines Corporation. On January 30, 1998, Centurion Mines Corporation changed its name to Grand Central Silver Mines, Inc.</p>
                                            <Link to={"/#"} className="site-button">Read More</Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 m-b30 our-story-thum">
                                        <img src={require("../../images/assets/silver-vertical-landscape.jpg")} className="radius-sm" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="title"> Our Current Ventures</h2>
                                    <p>Click to see which opportunity most aligns with your interests</p>
                                </div>
                                <div className="section-content row">
                                    <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="01">
                                            <div className="icon-lg">
                                                <Link to={"/bradshaw"} className="icon-cell"><i className="flaticon-robot-arm"></i></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Lincoln Cave Project</h2>
                                                <p>located in the oldest mining district in Utah</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="02">
                                            <div className="icon-lg">
                                                <Link to={"/mesa-mine"} className="icon-cell"><i className="flaticon-factory-1"></i></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Mesa Mine Project</h2>
                                                <p>potentially rich source of car battery minerals</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="03">
                                            <div className="icon-lg">
                                                <Link to={"/tidwell"} className="icon-cell"><i className="flaticon-fuel-station"></i></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Sagebrush Mine</h2>
                                                <p>40 miles in the San Rafael River Mining Area</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="04">
                                            <div className="icon-lg">
                                                <Link to={"/#"} className="icon-cell"><i className="flaticon-fuel-truck"></i></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Oil & Gas Industry</h2>
                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="05">
                                            <div className="icon-lg">
                                                <Link to={"/#"} className="icon-cell"><i className="flaticon-conveyor-1"></i></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Automative Manufacturing</h2>
                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="06">
                                            <div className="icon-lg">
                                                <Link to={"/#"} className="icon-cell"><i className="flaticon-engineer-1"></i></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Chemical Research</h2>
                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod </p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        {/* <div className="section-full about-progress">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-7 col-md-12 bg-white">
                                        <div className="max-w700  m-auto content-inner">
                                            <div className="section-head">
                                                <h2 className="title">Our Expertise at a Glance</h2>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer</p>
                                            </div>
                                            <div className="about-progress-box">
                                                <h6 className="text-primary">Web Development<span className="progress-num pull-right">85%</span></h6>
                                                <div className="about-progress-bar bg-gray-dark">
                                                    <div className="about-progress bg-primary wow slideInLeft" data-wow-delay="0.2s" data-wow-duration="2s" style={{width:"85%"}}></div>
                                                </div>
                                            </div>
                                            <div className="about-progress-box">
                                                <h6 className="text-primary">Web Designer<span className="progress-num pull-right">80%</span></h6>
                                                <div className="about-progress-bar bg-gray-dark">
                                                    <div className="about-progress bg-primary wow slideInLeft" data-wow-delay="0.4s" data-wow-duration="2s" style={{width:"85%"}}></div>
                                                </div>
                                            </div>
                                            <div className="about-progress-box">
                                                <h6 className="text-primary">Performance<span className="progress-num pull-right">75%</span></h6>
                                                <div className="about-progress-bar bg-gray-dark">
                                                    <div className="about-progress bg-primary wow slideInLeft" data-wow-delay="0.6s" data-wow-duration="2s" style={{width:"85%"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-12 p-a0">
                                        <img src={require("../../images/about/pic5.jpg")} alt="" className="img-cover "/>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <Footer10/>
            </>
        )
    }
}
export default About2;
