import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import Header10 from '../layout/header10'
import Footer10 from '../layout/footer10'

const bg = require('../../images/background/bg5.jpg')


export default function Contact1() {
    const [trigger, setTrigger] = useState(false);
    const [form, setForm] = useState({ email: "", name: "", phone: "", msg: "" });
    const [active, setActive] = useState(null);
    const [success, setSuccess] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);
    const [errors, setErrors] = useState({});
    const [buttonText, setButtonText] = useState("Send");
    const { email, project, name, phone, msg } = form;
    const changeForm = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleValidation = () => {
        let tempErrors = {};
        let isValid = true;

        if (name.length <= 0) {
          tempErrors["fullname"] = true;
          isValid = false;
        }
        if (email.length <= 0) {
          tempErrors["email"] = true;
          isValid = false;
        }
        if (msg.length <= 0) {
          tempErrors["message"] = true;
          isValid = false;
        }
        if (phone.length <= 0) {
            tempErrors["message"] = true;
            isValid = false;
        }
        if (project.length <= 0) {
        tempErrors["message"] = true;
        isValid = false;
        }

        setErrors({ ...tempErrors });
        console.log("errors", errors);
        return isValid;
      };
    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValidForm = handleValidation();

        if (isValidForm) {
            setSuccess(true);
            setButtonText("Sending");
            setTimeout(() => {
                setForm({ email: "", project:"", phone:"", name: "", msg: "" });
                setSuccess(false);
                setButtonText("Send")
            }, 2000);
        } else {
            setErrors(true);
            setTimeout(() => {
                setErrors(false);
                setButtonText("Send")
            }, 2000);

        const res = await fetch("/send-email", {
            body: JSON.stringify({
              email: email,
              project: project,
              phone: phone,
              fullname: name,
              message: msg,
            }),
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
          });

          const { error } = await res.json();
          console.log(res)
          if (error) {
            console.log(error);
            setSuccess(false);
            setShowFailureMessage(true);
            setButtonText("Send");
            return;
          }
          setSuccess(true);
          setShowFailureMessage(false);
          setButtonText("Send");
        }
    };

        return (
            <>
                <Header10/>
                <div className="page-content bg-white">
                    <div className="section-full content-inner contact-page-9 overlay-black-dark" style={{ background: "url(" + bg + ") 30% 100%" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-12 text-white">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 m-b30">
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary"><i className="ti-location-pin"></i></span>
                                                        Company Address
                                                    </h5>
                                                    <p>1812 W Sunset Blvd Ste1 PMB 320 <br></br> St George, UT 84770 USA </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 m-b30">
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary"><i className="ti-email"></i></span>
                                                        E-mail
                                                    </h5>
                                                    <p className="m-b0">hello@grandcentralsilvermines.com</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-12 m-b30">
                                    <form className="inquiry-form wow box-shadow bg-white fadeInUp" data-wow-delay="0.2s" onSubmit={handleSubmit} >
                                    <div
                                            className="returnmessage"
                                            data-success="Your message has been received, we will contact you soon."
                                            style={{ display: success ? "block" : "none" }}
                                        >
                                            <span className="contact_success">
                                                Your message has been received, we will contact you soon.
                                            </span>
                                        </div>
                                        <div
                                            className="empty_notice"
                                            style={{ display: showFailureMessage ? "block" : "none" }}
                                        >
                                            <span>Please Fill Required Fields!</span>
                                        </div>
                                        <h3 className="title-box font-weight-300 m-t0 m-b10">How To Get In Touch <span className="bg-primary"></span></h3>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                        <input onChange={changeForm} value={name} id="name" name="dzName" type="text" required className="form-control" placeholder="Name"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
                                                        <input onChange={changeForm} value={phone} id="phone" name="dzOther[Phone]" type="text" required className="form-control" placeholder="Phone"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-email text-primary"></i></span>
                                                        <input onChange={changeForm} value={email} id="email" name="dzEmail" type="email" className="form-control" required  placeholder="Your Email" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-check-box text-primary"></i></span>
                                                        <select onChange={changeForm} value={project} id="project" className="input-select form-control">
                                                            <option>Select Project</option>
                                                            <option>Mesa Mine Project</option>
                                                            <option>Lincoln Cave Project</option>
                                                            <option>TDW Claim Block</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-agenda text-primary"></i></span>
                                                        <textarea onChange={changeForm} value={msg} id="msg" name="dzMessage" rows="4" className="form-control" required placeholder="Please type your message here"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <button name="submit" type="submit" value="Submit" className="site-button button-md"> <span>{buttonText}</span> </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer10/>
            </>
        )
    }
