import React from 'react';
import { Link } from 'react-router-dom';



const Footer10 = ()=>{

    return (
        <>
            <footer className="site-footer text-white">
                <div className="footer-top bg-primary">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-5 col-xl-2 col-lg-2 col-sm-6 footer-col-4">
                                <div className="widget widget_services border-0">
                                    <h5 className="m-b30 text-white">Company</h5>
                                    <ul>
                                        <li><Link to="/about-1">About Us</Link></li>
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                        <li><Link to="/portfolio-details">Projects </Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-7 col-xl-2 col-lg-3 col-sm-6 footer-col-4">
                                <div className="widget widget_services border-0">
                                    <h5 className="m-b30 text-white">Useful Link</h5>
                                    <ul>
                                        <li><Link to="/contact-1">Contact</Link></li>
                                        <li><Link to="/portfolio-details">Portfolio</Link></li>
                                        <li><Link to="/team-1">Team</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4 col-lg-3 col-sm-6 footer-col-4">
                                <div className="widget widget_getintuch">
                                    <h5 className="m-b30 text-white ">Contact us</h5>
                                    <ul>
                                        <li><i className="ti-location-pin"></i><strong>address</strong>1812 W Sunset Blvd STE1 PMB 320 <br></br>St George, UT 85770 USA </li>
                                        <li><i className="ti-email"></i><strong>email</strong>hello@grandcentralsilvermines.com</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom  bg-primary">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 text-left "> <span>Copyright © 2022 DexignZone</span> </div>
                            <div className="col-md-6 col-sm-6 text-right ">
                                <div className="widget-link ">
                                    <ul>
                                        <li><Link to="/about-2"> About</Link></li>
                                        <li><Link to="/privacy-policy"> Privacy Policy</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer10;
