import React from 'react';
import { Link } from 'react-router-dom';

const Footer = ()=>{
	return (
		<>

			<footer className="site-footer style1">
				<div className="section-full p-t50 p-b20 bg-primary text-white overlay-primary-dark footer-info-bar">
					<div className="container">
						<div className="row">
							<div className="col-lg-3 col-md-6 col-sm-6 m-b30">
								<div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
									<div className="icon-content">
										<h5 className="dlab-tilte">
											<span className="icon-sm"><i className="ti-location-pin"></i></span>
											Company Address
										</h5>
										<p className="op7">123 Main St, City, State, USA</p>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6 m-b30">
								<div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
									<div className="icon-content">
										<h5 className="dlab-tilte">
											<span className="icon-sm"><i className="ti-email"></i></span>
											E-mail
										</h5>
										<p className="m-b0 op7">hello@gcsilver.com</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-top">
					<div className="container">
						<div className="row">
							<div className="col-md-4 col-sm-6">
								<div className="widget widget_about">
									<h4 className="footer-title">About Industry</h4>
									<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
									<Link to={"/about-2"} className="readmore">Read More</Link>
								</div>
							</div>
							<div className="col-md-4 col-sm-6">
								<div className="widget">
									<h4 className="footer-title">Usefull Link</h4>
									<ul className="list-2">
										<li><Link to={"/about-1"}>About Us</Link></li>
										<li><Link to={"/services-1"}>Services</Link></li>
										<li><Link to={"/privacy-policy"}>Privacy Policy</Link></li>
										<li><Link to={"/portfolio-grid-2"}>Projects </Link></li>
										<li><Link to={"/contact-1"}>Contact</Link></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-bottom footer-line">
					<div className="container">
						<div className="footer-bottom-in">
							<div className="footer-bottom-logo"><Link to={"/"}><img src={require("../../images/logo-light.png")} alt=""/></Link></div>
							<div className="footer-bottom-social">
								<ul className="dlab-social-icon dez-border">
									<li><Link className="fa fa-facebook" to={"/#"}></Link></li>
									<li><Link className="fa fa-twitter" to={"/#"}></Link></li>
									<li><Link className="fa fa-linkedin" to={"/#"}></Link></li>
									<li><Link className="fa fa-pinterest" to={"/#"}></Link></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}

export default Footer;
