



export const blogContent4 = [
    {
        image: require("../../images/our-work/leather/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/leather/pic1.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/leather/pic2.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/leather/pic3.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/leather/pic2.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
]



export const exibitionContent2 = [
      {
      projectName:"Silver",
      image: require("../../images/assets/baier.jpg")
    },
    {
      projectName:"Leather Products",
      image: require("../../images/assets/ey-lump-of-silver.jpg")
    },
    {
      projectName:"Leather processing",
      image: require("../../images/assets/silver peak (1).jpg")
    },
    {
      projectName:"Industrial Apartment",
      image: require("../../images/assets/istockphoto-468885849-612x612.jpg")
    },

  ];




export const projectContent2 = [
    {
        image: require('../../images/our-work/solarplant/pic1.jpg'),
        projectName: 'Manufacturing',
    },
    {
        image: require('../../images/our-work/solarplant/pic2.jpg'),
        projectName: 'Iron Making',
    },
    {
        image: require('../../images/our-work/solarplant/pic3.jpg'),
        projectName: 'Steel Pipes',
    },
    {
        image: require('../../images/our-work/solarplant/pic4.jpg'),
        projectName: 'Structural Steel',
    },
	{
        image: require('../../images/our-work/solarplant/pic3.jpg'),
        projectName: 'Steel Pipes',
    },
    {
        image: require('../../images/our-work/solarplant/pic4.jpg'),
        projectName: 'Structural Steel',
    },

]

export const servicesContent1 = [
    {
        serviceName:"Wind Turbines",
        icon:"flaticon-worker"
    },
    {
        serviceName:"Solar Panels",
        icon:"flaticon-factory"
    },
    {
        serviceName:"Maintenance",
        icon:"flaticon-settings"
    },
    {
        serviceName:"Maintenance",
        icon:"flaticon-engineer-1"
    }
]

export const serviceContent2 = [
    {
        serviceName:"Material Engineering",
        icon:"flaticon-worker"
    },
    {
        serviceName:"Power and Energy",
        icon:"flaticon-factory"
    },
    {
        serviceName:"Agricultural Engineering",
        icon:"flaticon-settings"
    },
    {
        serviceName:"Petroleum Engineering",
        icon:"flaticon-engineer-1"
    },
    {
        serviceName:"Material Engineering",
        icon:"flaticon-worker"
    },
]

export const servicesContent3 = [
    {
        image: require("../../images/our-work/solarplant/pic1.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Wind Turbines",
    },
    {
        image: require("../../images/our-work/solarplant/pic2.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Solar Panels",
    },
    {
        image: require("../../images/our-work/solarplant/pic3.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Maintenance",
    },
    {
        image: require("../../images/our-work/solarplant/pic2.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Solar Panels",
    },
    {
        image: require("../../images/our-work/solarplant/pic3.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Maintenance",
    }
]

export const servicesContent4 = [
    {
        image: require("../../images/our-services/pic1.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Wind Turbines",
    },
    {
        image: require("../../images/our-services/pic2.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Solar Panels",
    },
    {
        image: require("../../images/our-services/pic3.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Maintenance",
    },
    {
        image: require("../../images/our-services/pic1.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Wind Turbines",
    },
]

export const homeSliderContent2 = [
    {
        image: require("../../images/main-slider/slide4.jpg"),
        title:"GAS INDUSTRY",
        description:"Industry is ready to help you in making unique-looking and best website in a moment."
    },
    {
        image: require("../../images/main-slider/slide5.jpg"),
        title:"OIL INDUSTRY",
        description:"Industry is ready to help you in making unique-looking and best website in a moment."
    }
]

export const homeSliderContent3 = [
    {
        image: require("../../images/main-slider/slide21.jpg"),
        title:"Economy Needs A Healthy Steel Industry",
    },
    {
        image: require("../../images/main-slider/slide22.jpg"),
        title:"Economy Needs A Healthy Steel Industry",
    }
]

export const homeSliderContent4 = [
    {
        image: require("../../images/main-slider/slide1.jpg"),
        title:"Fast and Reliable Electrical services",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },
    {
        image: require("../../images/main-slider/slide2.jpg"),
        title:"Fast and Reliable Electrical services",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    }
]

export const homeSliderContent5 = [
    {
        image: require("../../images/main-slider/slide9.jpg"),
        title:"WE BUILD YOUR DREAM",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },
    {
        image: require("../../images/main-slider/slide10.jpg"),
        title:"WE ARE CONSTRUCT",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    }
]


export const homeSliderContent6 = [
    {
        image: require("../../images/main-slider/slide11.jpg"),
        title:"Solar Wind Power Technology ",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },
    {
        image: require("../../images/main-slider/slide12.jpg"),
        title:"Solar Wind Power Technology ",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    }
]

export const homeSliderContent7 = [
    {
        subTitle:"Our Latest Item Food Industry",
        image: require("../../images/main-slider/slide13.jpg"),
        title:"Our Latest Item Food Industry ",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },
    {
        subTitle:"Our Latest Item Food Industry",
        image: require("../../images/main-slider/slide14.jpg"),
        title:"Our Latest Item Food Industry ",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    }
]

export const homeSliderContent8 = [
    {
        subTitle:"INVESTMENT OPPORTUNITIES IN UTAH",
        image: require("../../images/assets/aerial.jpg"),
        title:"Welcome to Grand Central Silver",
    },
    {
        subTitle:"INVESTMENT OPPORTUNITIES IN UTAH",
        image: require("../../images/assets/silver-sunset.jpg"),
        title:"Welcome to Grand Central Silver",
    }
]

export const homeSliderContent9 = [
    {
        subTitle:"INVESTMENT OPPORTUNITIES IN LEATHER",
        image: require("../../images/main-slider/slide23.jpg"),
        title:"Nuclear Power Plants",
        description:"Great Lessons You Can Learn From Nuclear Plant"
    }
]

export const homeSliderContent10 = [
    {
        subTitle2:"Sale Car – Buy Car – Car Service",
        image: require("../../images/main-slider/slide29.jpg"),
        title:"Auto Mobile Industry",
        description:" With 19+ years’ experience in combining traditions and innovations, Arty creates settings to inspire the way people live, learn, heal, work and play."
    }
]

export const homeSliderContent11 = [
    {
        subTitle:"Value Creation Form Waste ",
        image: require("../../images/main-slider/slide19.jpg"),
        title:"Plastic Waste Managment & Recycling",
        description:"Powering Progress Through Plastic"
    }
]
