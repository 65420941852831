import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header10 from '../layout/header10'
import Footer10 from '../layout/footer10'

const bg = require('../../images/banner/bnr4.jpg')

class BradshawDetails extends Component {


    render() {
        return (
            <>
                <Header10/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">Lincoln Cave Project</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>Portfolio</li>
                                        <li>Portfolio Detail</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block">
                        <div className="section-full content-inner bg-white">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 m-b30">
                                        <h2 className="text-black font-weight-600 m-b15">Project Information</h2>
                                        <p>Grand Central Silver Mines Lincoln and Bradshaw Mining District’s properties that they have been exploring for over 15 years has optioned their rights in the area to Ivanhoe Electric.  The Lincoln Copper-Gold-Silver Project area encompasses numerous historic small underground workings with little record of past production.  It is thought to be the oldest mining district in Utah.  Prior to Ivanhoe Electric’s involvement, little modern exploration occurred on the project. </p>

                                        <p>Following Ivanhoe Electric’s completion of a Typhoon survey in the Fall of 2022 and the inversion of the data into three-dimensional model by their subsidiary Computational Sciences Inc., Ivanhoe Electric has commenced drilling on the large-scale geophysical features including chargeability, resistivity, magnetic ad density anomalies identified below the historic mine workings, which could represent skarn and / or porphyry-style mineralization.</p>

                                        <p>
                                        Grand Central Silver Mines’ Option Agreement includes annual cash payments and a retained royalty.
                                        </p>
                                        <div className="row widget widget_getintuch widget_getintuch-pro-details m-lr0">
                                            {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-user"></i>
                                                    <strong>CLIENT</strong> Martin Stewart
                                                </div>
                                            </div> */}
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-location-pin"></i>
                                                    <strong>LOCATION</strong>Garfield County UT
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-ruler-alt-2"></i>
                                                    <strong>SIZE</strong>10,000<sup>acres</sup>
                                                </div>
                                            </div>
                                            {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-home"></i>
                                                    <strong>TYPE</strong>Residential Project
                                                </div>
                                            </div> */}
                                        </div>
                                        <h2 className="text-black font-weight-600 m-b15">Current Scope Of Work</h2>
                                        <p>The drill program presently underway on the Lincoln Copper-Gold-Silver Project is being conducted on a combination of private patented mining claims and unpatented mining claims near Minersville, Utah. (Junior Mining Network August 02, 2023).</p>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-lg-12 m-b30">
                                                <img alt="" src={require("../../images/about/pic3.jpg")}/>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <img alt="" src={require("../../images/our-services/pic2.jpg")}/>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <img alt="" src={require("../../images/our-services/pic3.jpg")}/>
                                            </div>
                                            <div className="col-lg-12 m-b30">
                                                <img alt="" src={require("../../images/about/about2.jpg")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer10/>
            </>
        )
    }
}
export default BradshawDetails;
