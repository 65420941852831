import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header10'
import Footer from '../layout/footer10'
import ExibitionSlider1 from '../element/exibitionSlider1'
import {exibitionContent2,homeSliderContent8} from '../element/SliderContent'
import HomeSlider from '../element/homeSlider2'
import ScrollToTop from '../element/scrollToTop';
import Team6 from '../element/team6'

// import '../../css/skin/skin-8.min.css';



class Index10 extends Component {


    render() {
        return (
            <>
            <Header/>

            <div className="page-content bg-white home-slider-10">
				<HomeSlider
				data={homeSliderContent8}
				contentWrapperCls="sliderStyle10"
				subTitleCls="text-primary"
				btnCls="text-primary"
				btnText="CONTINUE THE JOURNEY"
				/>
                <div className="content-block">
                    <div className="section-full content-inner-2 exhibition-bx">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <ExibitionSlider1 data={exibitionContent2}/>
                                </div>
                                <div className="col-lg-6 m-b30 wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="content-bx1">
                                        <div className="section-head">
                                            <h2 className="title"> Our Aim</h2>
                                            <p>We are committed to finding and exploiting mineralization in the continental United States to provide our country with the necessary mining elements to empower the future</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full">
                        <div className="row spno about-industry">
                            <div className="col-lg-4 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.3s">
                                <div className="dlab-img-effect zoom">
                                    <img src={require("../../images/assets/silver-melted.jpg")} alt="" className="img-cover"/>
                                </div>
                            </div>
                            <div className="col-lg-4 bg-secondry text-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s">
                                <div className="service-box style2">
                                    <div>
                                        <h2 className="title"><span>Invest  </span> <br/>in the Future</h2>
                                        <p>Empowering national reduction in fossil fuel consumption</p>
                                        <Link to="/vision" className="site-button btnhover21 outline white outline-2">VISION STATEMENT</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.9s">
                                <div className="dlab-img-effect zoom">
                                    <img src={require("../../images/assets/istockphoto-163263590-612x612.jpg")} alt="" className="img-cover"/>
                                </div>
                            </div>
                            <div className="col-lg-4 bg-primary text-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.3s">
                                <div className="service-box style2">
                                    <div>
                                        <h2 className="title"><span>Current </span> <br/>Ventures</h2>
                                        <p>Get to know our current projects in the southwestern US</p>
                                        <Link to="/projects" className="site-button btnhover21 outline white outline-2">Our Projects</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s">
                                <div className="dlab-img-effect zoom">
                                    <img src={require("../../images/assets/silver-on-wood.jpg")} alt="" className="img-cover"/>
                                </div>
                            </div>
                            <div className="col-lg-4 bg-primary text-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s">
                                <div className="service-box style2">
                                    <div>
                                        <h2 className="title text-white"><span>Understand </span> <br/>Our Story</h2>
                                        <p>furthering our mission since 1984</p>
                                        <Link to="/about-2" className="site-button btnhover21 outline white outline-2">ABOUT US</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="section-full content-inner bg-white">
                        <div className="container">
                            <div className="section-head style2 text-center">
                                <h2 className="title">Our Team</h2>
                                <p>There are many variations of passages of Lorem Ipsum typesetting industry has been the industry's standard dummy text ever since the been when an unknown printer.</p>
                            </div>
                            <Team6 />

                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
            <ScrollToTop className="style3"/>
            </>
        )
    }
}
export default Index10;
