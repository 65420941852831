import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import Index from './pages/index';
import About2 from './pages/about-2';
import Contact1 from './pages/contact-1';
import Error404 from './pages/error-404';
import Faq2 from './pages/faq-2';
import Index10 from './pages/index-10';
import BradshawDetails from './pages/bradshaw';
import TidwellDetails from './pages/tidwell';
import MesaDetails from './pages/mesa-mine';
import Projects from './pages/projects';
import Vision from './pages/vision';


class Router extends Component {
    render() {
        return (

			//<BrowserRouter>
			<BrowserRouter>
                <div className="page-wraper">
                        <Route path='/' exact component={Index10} />
                        <Route path='/about-2' component={About2} />
                        <Route path='/contact-1' component={Contact1} />
                        <Route path='/error-404' component={Error404} />
                        <Route path='/faq-2' component={Faq2} />
                        <Route path='/projects' component={Projects} />
                        <Route path='/bradshaw' component={BradshawDetails} />
                        <Route path='/tidwell' component={TidwellDetails} />
                        <Route path='/mesa-mine' component={MesaDetails} />
                        <Route path='/vision' component={Vision} />

                </div>

            </BrowserRouter>

        )
    }
}

export default Router;
