import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header10 from '../layout/header10'
import Footer from '../layout/footer'

var bnr3 = require('./../../images/banner/bnr3.jpg');

class Vision extends Component {


    render() {
        return (
            <>
                <Header10/>

                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">About Us</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to={"/"}>Home</Link></li>
                                        <li>About Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block">
                        <div className="section-full content-inner bg-white">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-12 m-b30">
                                        <div className="our-story">
                                            <span>OUR VISION</span>
                                            <h2 className="title">Looking <br/>Forward To Our Future <br/></h2>
                                            <p>Recently, GSLM has partnered with Ivanhoe Electric (TSX: IE) a subsidiary of Ivanhoe who is a global leader in deep tunnel mining to undertake an exploratory drilling program in the historic mining districts known as Bradshaw and Lincoln located in Beaver County, Utah
The district has a promising mineralization profile. The results of the drilling will be forthcoming once they become available

GSLM has acquired two additional properties to add to the company's portfolio

The first is a property purchased by GSLM that has the potential of yielding through development Uranium-238. An adjacent property mined in the 1950's produced many millions of pounds of Uranium. The property is currently being evaluated with surface mapping and sampling by GSLM

The second  property is under option by GSLM with the intent to purchase is highly mineralized in cobalt, copper and some nickel.  All three minerals were found to be present in previous underground sampling

With the demand for automobile electrification and nuclear power as a lower emission alternative to fossil fuel generated electrical power GSLM is positioning itself to help meet the needs and requirements for American energy production in the decades ahead</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 m-b30 our-story-thum">
                                        <img src={require("../../images/assets/silver-vertical-landscape.jpg")} className="radius-sm" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}
export default Vision;
