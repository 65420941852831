import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import Slider from "react-slick";

class Team6 extends Component {

    render() {

        return (
            <>
                <div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6">
						<div className="dlab-box m-b30 dlab-team6">
							<div className="dlab-media">
								<Link to={"#"}>
									<img alt="" src={require("../../images/assets/Headshots/Headshots-2.jpg")}/>
								</Link>
							</div>
							<div className="dlab-info">
								<h4 className="dlab-title"><Link to={"#"}>Michael Major</Link></h4>
								<span className="dlab-position">President</span>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-6">
						<div className="dlab-box m-b30 dlab-team6">
							<div className="dlab-media">
								<Link to={"#"}>
									<img alt="" src={require("../../images/assets/Headshots/Headshots-1.jpg")}/>
								</Link>
							</div>
							<div className="dlab-info">
								<h4 className="dlab-title"><Link to={"#"}>Mark Prosken</Link></h4>
								<span className="dlab-position">Board Member</span>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-6">
						<div className="dlab-box m-b30 dlab-team6">
							<div className="dlab-media">
								<Link to={"#"}>
									<img alt="" src={require("../../images/assets/Headshots/Headshots-3.jpg")}/>
								</Link>
							</div>
							<div className="dlab-info">
								<h4 className="dlab-title"><Link to={"#"}>William Aldrich</Link></h4>
								<span className="dlab-position">Board Chairman</span>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-6">
						<div className="dlab-box m-b30 dlab-team6">
							<div className="dlab-media">
								<Link to={"#"}>
									<img alt="" src={require("../../images/assets/Headshots/Headshots-4.jpg")}/>
								</Link>
							</div>
							<div className="dlab-info">
								<h4 className="dlab-title"><Link to={"#"}>James Cain</Link></h4>
								<span className="dlab-position">Board Member</span>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-6">
						<div className="dlab-box m-b30 dlab-team6">
							<div className="dlab-media">
								<Link to={"#"}>
									<img alt="" src={require("../../images/assets/Headshots/Headshots-5.jpg")}/>
								</Link>
							</div>
							<div className="dlab-info">
								<h4 className="dlab-title"><Link to={"#"}>Paul Evans</Link></h4>
								<span className="dlab-position">Board Member</span>
							</div>
						</div>
					</div>
				</div>

            </>
        )
    }
}
export default Team6;
