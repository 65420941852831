import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header10 from '../layout/header10'
import Footer from '../layout/footer'

const bg = require('../../images/banner/bnr4.jpg')

class MesaDetails extends Component {


    render() {
        return (
            <>
                <Header10/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">Mesa Mine Project</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>Portfolio</li>
                                        <li>Portfolio Detail</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block">
                        <div className="section-full content-inner bg-white">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 m-b30">
                                        <h2 className="text-black font-weight-600 m-b15">Project Information</h2>
                                        <p>The Mesa Deposit mineralization in southeastern Utah was first discovered in the spring of 1968 and produced and processed copper intermittently from 1971 to and ending in 1974 due to lack of sufficient water to process the ore.</p>

                                        <p>The Mesa deposit is located at the contact of a northwesterly trending paleochannel deposit of the Triassic Shinarump Member of the Chinle Formation and the underlying Moenkopi Formation.  The mineralized zone is tabular in shape and is composed of chalcopyrite, bornite, digenite, covellite, chalcocite, sphalerite, pyrite, with minor amounts of cobalt, nickel, lead, molybdenum, uranium, vanadium, and silver.</p>
                                        <p>The mineralizing fluid is a low temperature, dilute aqueous medium such as ground water.  All the elements enriched in the mineralized zone are soluble at low concentrations in an oxidizing, neutral to slightly acidic environment.  Precipitation of the mineralizing elements as sulfides was caused by a decrease in the Eh (electric potential) of the mineralized solution.  Co, Ni, Mo and Zn was not sought after during the uranium boom of the 50’s and 60’s however, since the advent of electrical vehicles (EV) both cobalt and nickel are being highly sought after for their importance in the manufacturing of EV batteries, which makes deposits such as the Mesa a prime prospective location to produce these vital metals domestically. </p>
                                        <p>The Mesa is 6,600 feet east to west and 2,400 feet north to south.  Copper mineralization has been traced along the Shinarump/Moenkopi contact around the perimeter of the mesa as further evidence that mineralization underlies the mesa.  The thickness of mineralization will vary due to the pinch and swell nature of the contact.  The average historical mining thickness was 6 feet.</p>
                                        <p>
                                        The need to produce vital strategic minerals domestically both safely and securely is very important, without violating basic human rights by utilizing child labor under brutal and deplorable conditions in mines controlled by the CCP.
                                        </p>
                                        <div className="row widget widget_getintuch widget_getintuch-pro-details m-lr0">
                                            {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-user"></i>
                                                    <strong>CLIENT</strong> Martin Stewart
                                                </div>
                                            </div> */}
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-location-pin"></i>
                                                    <strong>LOCATION</strong>Colorado Basin, CO
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-ruler-alt-2"></i>
                                                    <strong>SIZE</strong>4000<sup>acres</sup>
                                                </div>
                                            </div>
                                            {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                                                <div className="pro-details">
                                                    <i className="ti ti-home"></i>
                                                    <strong>TYPE</strong>Residential Project
                                                </div>
                                            </div> */}
                                        </div>
                                        <h2 className="text-black font-weight-600 m-b15">Current Scope Of Work</h2>
                                        <p>The property includes 32 unpatented mining claims, totaling 600+ acres which cover the past producing Colt Mesa mine. The property boasts a strata-bound, sediment-hosted channel deposit, which carries copper with associated cobalt, zinc, nickel and molybdenum. The  deposit is renowned for brilliant, multicolored  copper oxides.</p>
                                        <table>
                                            <tr>
                                                <th>2020 UG sampling returned peak values of:
                                                </th>
                                            </tr>
                                            <tr className="tab">
                                                <ul >
                                                    <li>Cu 	7.670%</li>
                                                    <li>Co	0.363%</li>
                                                    <li>Zn  2.670%</li>
                                                    <li>Ni  0.114%</li>
                                                </ul>
                                            </tr>
                                            <tr>
                                                <th>
                                                2018 UG sampling returned peak values of:
                                                </th>
                                            </tr>
                                            <tr className="tab">
                                                <ul >
                                                    <li>Cu 	1.39%</li>
                                                    <li>Co	2.31%</li>
                                                    <li>Zn  9.31%</li>
                                                    <li>Ni  +1.00%</li>
                                                </ul>
                                            </tr>
                                            <tr>
                                                <th>Reported 1975 grab-sampling with peak values of:</th>
                                            </tr>
                                            <tr className="tab">
                                            <ul >
                                                <li>Cu 	29.50%</li>
                                                <li>Co	0.67%</li>
                                                <li>Zn  3.30%</li>
                                                <li>Ni  0.27%</li>
                                            </ul></tr>
                                        </table>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-lg-12 m-b30">
                                                <img alt="" src={require("../../images/about/pic3.jpg")}/>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <img alt="" src={require("../../images/our-services/pic2.jpg")}/>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <img alt="" src={require("../../images/our-services/pic3.jpg")}/>
                                            </div>
                                            <div className="col-lg-12 m-b30">
                                                <img alt="" src={require("../../images/about/about2.jpg")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}
export default MesaDetails;
