import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header10 from '../layout/header10'
import Footer from '../layout/footer'
import { Accordion } from 'react-bootstrap';

const faqContent=[
    {
        question:"1. Is Our Company Currently Listed On A Public Market?",
        answer:"GCSM is not currently listed on any stock exchange."
    },
    {
        question:"2. Number of outstanding shares",
        answer:"We currently have 40 million shares issued. "
    },
    {
        question:"3. Do you hold regular shareholder meetings?",
        answer:"Currently there are no regular shareholder meetings planned. "
    },
    {
        question:"4. How do you communicate new information?",
        answer:"We will post updates to our website when new information becomes available. "
    },
]

const bg = require('../../images/banner/bnr3.jpg')

const Faq2 = () =>  {
	const [activeDefault, setActiveDefault] = useState(-1);


        return (
            <>
                <Header10/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">Faq 2</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>Faq 2</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block">
                        <div className="section-full content-inner bg-white">
                            <div className="container">
                                <div className="section-head text-black text-left">
                                    <h3>General Questions</h3>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Accordion className="dlab-accordion faq-2 box-sort-in m-b30  ">
                                            {faqContent.map((faq, i) =>(
                                                <div className="panel">
                                                    <Accordion.Item eventKey={`${i}`}>
                                                        <Accordion.Header as="h6">
                                                            {faq.question}
                                                        </Accordion.Header>
                                                        <Accordion.Body eventKey={`${i}`}>
                                                            <div>{faq.answer}</div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </div>
                                            ))}
                                        </Accordion>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer/>
            </>
        )

}
export default Faq2;
